import React, {Component} from 'react';
import $ from 'jquery';
import years from '../assets/images/25-years.png';
import Celebratecss from '../stylecomponents/celebrate.css';
class CelebrateAnniversary extends Component {
    constructor(){
    super();
    this.state = {
      

    };
}


render(){
const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>


<section className="main_wrap" id="about_us">
    <div className="container">
        <div className="flex_row">
            <div className="celebrate_info">
                <h2>Celebrating our 25th Anniversary!</h2>
                <p>Our first 2 waves of epic 1x1 NFTs in this collection will be paired with a real, one of a kind, custom made bear, that will never be replicated in the same way. Seize your chance to own your own one of a kind Build-A-Bear, inspired by our 25th anniversary celebration.</p>
                <p>Additionally, GIVE is one of Build-A-Bear’s core values and has been a key element of the brand throughout our history. Today, our giving programs come to life through Build-A-Bear Foundation. Since its formation in 2004, Build-A-Bear Foundation has contributed more than $22 million and 1.5 million furry friends to charitable organizations around the world. In honor of our anniversary and our first NFT collection, we will also be donating a portion of the proceeds on the first sale of this collection to Build-A-Bear Foundation.</p>
            </div>
            <div className="celebrate_box">
                <img src={years} alt="" />
            </div>
            
        </div>
    </div>
</section>


</React.Fragment>
    )
}
}
export default CelebrateAnniversary;
