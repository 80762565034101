import React, {Component} from 'react';
import $ from 'jquery';
import meetbearvideo from '../assets/videos/hero-video.mp4';
import choosecollection from '../assets/images/shadow-text.png';
import card1 from '../assets/images/card-1.png';
import mobilecard1 from '../assets/images/card-title-1.png';
import card2 from '../assets/images/card-2.png';
import Choosecardcss from '../stylecomponents/choosecard.css';
class ChooseCollectionCard extends Component {
    constructor(){
    super();
    this.state = {
      

    };
}

render(){
const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>


<section className="choose_collection_wrap">
    <div className="container">
        <div className="choose_collection">
            <img className="hide_mobile" src={choosecollection} alt="" />
            <img className="mobile_show" src={mobilecard1} alt="" />
            <p>NFTs will be released in three waves, bid or buy now to take part in this special opportunity!</p>
        </div>
        <div className="flex_row">
            <div className="col-sm-4 card_box">
                <div className="inner_card">
                    <video src={meetbearvideo} autoPlay loop muted />
                    <p className="overflow_text">1/1 Limited Edition Build-A-Bear 25th Celebration Collectible NFT Featuring Swarovski® crystals (includes the physical bear) </p>
                    <p>Opening Bid of <span>$2,500</span></p>
                    <p>This Limited Edition Build-A-Bear 25th Celebration Collectible NFT Featuring Swarovski® crystals is one of a kind. Both a physical and digital collector's item that cannot be missed.</p>
                    <a href="#">Auction Starts Oct 4th at 12PM ET </a>
                </div>
            </div>
            <div className="col-sm-4 card_box">
                <div className="inner_card">
                    <img src={card2} alt="" />
                    <p className="overflow_text">1/1 25th Celebration Silver Bears  (includes the physical bear) </p>
                    <p>Opening Bid of <span>$1,000</span></p>
                    <p>This limited collection of five Silver Anniversary Bears are ultra rare with unique accessories and timeless charm. Each chic bear is a gala themed showstopper. </p>
                    <a href="#">Auction Starts Nov 2nd at 12PM ET </a>
                </div>
            </div>
            <div className="col-sm-4 card_box">
                <div className="inner_card">
                    <img src={card2} alt="" />
                    <p className="overflow_text">5,000 Generative Bear Digital Collectibles (includes lorem ipsum)</p>
                    <p>Starting at <span>$45.00</span></p>
                    <p>The Build-A-Bear experience you know and love has made its way to web3! 5,000 unique bears each with their own traits and personality. Which bear will you reveal? </p>
                    <a href="#">Auction Starts Nov 2nd at 12PM ET </a>
                </div>
            </div>
        </div>
    </div>
</section>


</React.Fragment>
    )
}
}
export default ChooseCollectionCard;
