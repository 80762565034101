import React from 'react';
import './App.css';
import {HashRouter as Router, withRouter} from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './container/Home';
import CelebrateAnniversary from './container/CelebrateAnniversary';
import ChooseCollectionCard from './container/ChooseCollectionCard';
import Staytouch from './container/Staytouch';
import MeetBear from './container/MeetBear';
import Faq from './container/Faq';
import FollowBear from './container/FollowBear';
function App() {
  return (
    <React.Fragment>
      	<Router>
      	<Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/celebrate-anniversary" component={CelebrateAnniversary}/>
          <Route path="/choose-collection-card" component={ChooseCollectionCard}/>
          <Route path="/meet-bear" component={MeetBear}/>
          <Route path="/staytouch" component={Staytouch}/>
          <Route path="/faq" component={Faq}/>
          <Route path="/followbear" component={FollowBear}/>
		</Switch>
		</Router>
    </React.Fragment>
  );
}

export default App;
