import React from 'react';
import sweetlogo from '../assets/images/sweetlogo.png';
import icon1 from '../assets/images/icon-01.png';
import icon2 from '../assets/images/icon-02.png';
import icon3 from '../assets/images/icon-03.png';
import icon4 from '../assets/images/icon-04.png';
import Footercss from '../stylecomponents/footer.css';
const Footer = () =>{
return(

<React.Fragment>


<footer className="footer_wrap">
    <div className="container">
        <div className="flex_row">
            <div className="sweet_logo">
                <a href="#"><img src={sweetlogo} alt="" /></a>
            </div>
            <div className="footer_widget">
                <div className="flex_row">
                    <div className="widget_nav">
                        <h4>Help</h4>
                        <ul>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Help Center</a></li>
                            <li><a href="#">FAQ</a></li>
                        </ul>
                    </div>
                    <div className="widget_nav">
                        <h4>Sweet</h4>
                        <ul>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#">Press Inquiries</a></li>
                            <li><a href="#">Partnerships</a></li>
                        </ul>
                    </div>
                    <div className="widget_nav">
                        <h4>More Info</h4>
                        <ul>
                            <li><a href="#">Mailing List</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms of Use</a></li>
                        </ul>
                    </div>
                    <div className="widget_nav">
                        <h4>Follow Us</h4>
                        <a href="#"><img src={icon1} alt="" /></a>
                        <a href="#"><img src={icon2} alt="" /></a>
                        <a href="#"><img src={icon3} alt="" /></a>
                        <a href="#"><img src={icon4} alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

</React.Fragment>

)
}
export default Footer
