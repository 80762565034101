import React, {Component} from 'react';
import $ from 'jquery';
import follow from '../assets/images/follow-title.png';
import follow1 from '../assets/images/facebook.png';
import follow2 from '../assets/images/instagram.png';
import follow3 from '../assets/images/twitter.png';
import Followbearcss from '../stylecomponents/followbear.css';
class FollowBear extends Component {
    constructor(){
    super();
    this.state = {
      

    };
}

render(){
const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>
<section class="follow_wrap">
    <div class="container">
        <div class="stay_intouch">
            <img src={follow} alt="" />
        </div>
        <div class="socials_icons_box">
            <a href="#"><img src={follow1} alt="" /> </a>
            <a href="#"><img src={follow2} alt="" /> </a>
            <a href="#"><img src={follow3} alt="" /> </a>
        </div>
        
    </div>
</section>




</React.Fragment>
    )
}
}
export default FollowBear;
