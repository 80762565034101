import React, {Component} from 'react';
import $ from 'jquery';
import playbutton from '../assets/images/hero-video.png';
import playbtnvideo from '../assets/images/playbtn.png';
import playbtn2 from '../assets/images/mobileplaybtn.png';
import meetbearvideo from '../assets/videos/hero-video.mp4';
import video from '../assets/images/Video.png';
import playbtn from '../assets/images/Play-Pause-Icons.png';
import Header from '../component/Header';
import Footer from '../component/Footer';
import CelebrateAnniversary from '../container/CelebrateAnniversary';
import ChooseCollectionCard from '../container/ChooseCollectionCard';
import FollowBear from '../container/FollowBear';
import Staytouch from '../container/Staytouch';
import Faq from '../container/Faq';
import MeetBear from '../container/MeetBear';
import Herocss from '../stylecomponents/hero.css';
class Home extends Component {
    constructor(){
    super();
    this.state = {
      

    };
}
componentDidMount(){

  $(".menu_btn").click(function(){
    $(".menubar").fadeIn();
  });
  $(".closebtn svg").click(function(){
    $(".menubar").fadeOut();
  });

// $(".play_btn_bottom img,.playbtn a img").click(function(){
//     $("video").get(0).pause();
// });

$("#pause_btnvideo").click(function(){
    $("#Video1").get(0).pause();
    $("#pause_btnvideo").hide();
    $("#play_btnvideo").show();
});

$("#play_btnvideo").click(function(){
    $("#Video1").get(0).play();
    $("#play_btnvideo").hide();
    $("#pause_btnvideo").show();
});


$("#pause_btnvideo2").click(function(){
    $("#Video1").get(0).pause();
    $("#pause_btnvideo2").hide();
    $("#play_btnvideo2").show();
});

$("#play_btnvideo2").click(function(){
    $("#Video1").get(0).play();
    $("#play_btnvideo2").hide();
    $("#pause_btnvideo2").show();
});



  $(document).ready(function(){
    $('li a[href^="#"]').on('click',function (e) {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function () {
        });
    });
});

}


render(){


const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>
<Header />
<section className="banner_wrap">
<video id="Video1" src={meetbearvideo} autoPlay loop muted />
    <div className="container">
        <div className="videobox">
            <img src={video} alt="" />
            <div className="playbtn">
                <a href="#" id="pause_btnvideo2"><img src={playbtn} alt="" /></a>
                <a href="#" id="play_btnvideo2"><img src={playbtn2} alt="" /></a>

            </div>
        </div>
        <div className="flex_row">
            <div className="banner_info">
                <h2>Build-A-Bear 25th Celebration NFT Collection</h2>
                <p>Build-A-Bear in partnership with Sweet NFT, is launching a series of digital collectibles in honor of its 25th anniversary.  We are always looking for ways to celebrate and innovate. Our hope is to delight with unique digital art, inspire with an exclusive series of NFTs that are paired with utility in real life, and dazzle all with one Build-a-Bear collectible that will live in your home and in your digital collection, providing bragging rights both in person and in the virtual world.</p>
                <a href="#">Learn More</a>
            </div>
        </div>
        <div className="play_btn_bottom" >
        <img id="pause_btnvideo" src={playbutton} alt="" />
        <img id="play_btnvideo" src={playbtnvideo} alt="" />
        </div>
    </div>
</section>
<CelebrateAnniversary />
<ChooseCollectionCard />
<MeetBear />
<Faq />
<Staytouch />
<FollowBear />
 <Footer />
</React.Fragment>
 )
}
}
export default Home;
