import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/images/Logo.png';
import mobilelogo from '../assets/images/mobilelogo.png';
import hamburger from '../assets/images/hamburger-menu.png';
import userlogin from '../assets/images/userlogin.png';
import Headercss from '../stylecomponents/header.css';
const Header = () =>{

return(

<React.Fragment>

<header className="header_warp">
    <div className="container">
        <div className="flex_row" id="header_menu">
            <div className="menu_btn">
                <a href="#" id="bt" onclick="toggle(this)"><img src={hamburger} alt="" /></a>
            </div>
            <div className="logobox">
                <a href="#"><img className="hidelogo" src={logo} alt="" /></a>
                <a href="#"><img className="mobile_logo" src={mobilelogo} alt="" /></a>
            </div>
            <div className="menubar" id="cont">
                <div className="closebtn">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.30086 0.41469C1.78017 -0.106003 0.935943 -0.106003 0.415249 0.41469C-0.105457 0.935383 -0.105457 1.77961 0.415249 2.3003L8.11491 9.99994L0.415329 17.6995C-0.105377 18.2202 -0.105377 19.0645 0.415329 19.5851C0.936023 20.1058 1.78025 20.1058 2.30094 19.5851L10.0005 11.8855L17.7001 19.5851C18.2208 20.1058 19.065 20.1058 19.5857 19.5851C20.1064 19.0645 20.1064 18.2202 19.5857 17.6995L11.8861 9.99994L19.5857 2.3003C20.1065 1.77961 20.1065 0.935383 19.5857 0.41469C19.065 -0.106003 18.2209 -0.106003 17.7001 0.41469L10.0005 8.11434L2.30086 0.41469Z" fill="#fff"></path>
                    </svg>
                </div>
                <ul>
                    <li><a href="#about_us">About</a></li>
                    <li><a href="#meet_bers_section">Meet the Bears</a></li>
                    <li><a href="#faq_section">FAQ</a></li>
                    <li><a href="#staytouch_wrap">Subscribe</a></li>
                    <li><a href="#" className="mobile_list">Sign In</a></li>
                    <li><a href="#" className="mobile_list">Log In</a></li>
                </ul>
            </div>
            <div className="user_login">
                <a href="#" className="hide_btn">Sign Up/Log In</a>
                <a href="#" className="mobile_login"><img src={userlogin} alt="" /></a>
            </div>
        </div>
    </div>
</header>

</React.Fragment>

)
}
export default Header
