import React, {Component} from 'react';
import $ from 'jquery';
import faq from '../assets/images/faq-title.png';
import faqtitle from '../assets/images/faq_mobiletitlee.png';
import faqcss from '../stylecomponents/faq.css';
class Faq extends Component {
    constructor(){
    super();
    this.state = {
      

    };
}

render(){
const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>



<section className="faq_wrap" id="faq_section">
    <div className="container">
        <div className="faq_title">
            <img src={faq} alt="" />
            <img className="faq_mobile_title" src={faqtitle} alt="" />
        </div>

        <div className="according_box">
            <div className="row">
              <div className="col">
                <div className="tabs">
                  <div className="row">
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck1" />
                        <label className="tab-label" for="chck1">What are digital collectibles or NFTs?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck2" />
                        <label className="tab-label" for="chck2">Why is Build-A-Bear releasing NFTs?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck3" />
                        <label className="tab-label" for="chck3">How can I participate?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck4" />
                        <label className="tab-label" for="chck4">How do I purchase a Build-A-Bear NFT?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck5" />
                        <label className="tab-label" for="chck5">Where will my NFT go after I buy it?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck6" />
                        <label className="tab-label" for="chck6">Are there eligibility requirements or other conditions to redeem these benefits?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck7" />
                        <label className="tab-label" for="chck7">Do I need cryptocurrency to buy these NFTs?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck8" />
                        <label className="tab-label" for="chck8">What forms of payment do you accept?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck9" />
                        <label className="tab-label" for="chck9">Can I trade my Build-A-Bear NFT?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck10" />
                        <label className="tab-label" for="chck10">Is the NFT transferrable?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck11" />
                        <label className="tab-label" for="chck11">What Blockchain are these NFTs on?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck11" />
                        <label className="tab-label" for="chck11">Can I send the initial NFT to an external wallet?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck12" />
                        <label className="tab-label" for="chck12">I already have a Sweet account, do I need to create a new account?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck13" />
                        <label className="tab-label" for="chck13">Are my collectibles unique?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck14" />
                        <label className="tab-label" for="chck14">I won the Epic or Ultra Rare NFT auction that comes with a physical Buil-A-Bear, how do I claim it? </label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck15" />
                        <label className="tab-label" for="chck15">I won the Epic or Ultra Rare NFT auction that comes with a physical Buil-A-Bear, how do I claim it? </label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. upidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck16" />
                        <label className="tab-label" for="chck16">Where can I learn more about digital collectibles, NFTs and blockchain?</label>
                        <div className="tab-content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                    <div className="col-sm-12 sidegapp">
                      <div className="tab">
                        <input type="checkbox" id="chck17" />
                        <label className="tab-label" for="chck17">I have a question not addressed in this FAQ. Who do I contact?</label>
                        <div className="tab-content">Lorem ipsum dagna occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
    </div>
</section>





</React.Fragment>
    )
}
}
export default Faq;
