import React, {Component} from 'react';
import $ from 'jquery';
import meetbearvideo from '../assets/videos/meetbear.mp4';
import meetbear from '../assets/images/meet-bear.png';
import mobileplaybtn1 from '../assets/images/mobileplaybtn.png';
import meetbearplay from '../assets/images/meetbear_play.png';
import mobiletitle from '../assets/images/mobile-title-bear.png';
import playbutton from '../assets/images/playbutton.png';
import playbtn from '../assets/images/Play-Pause-Icons.png';
import Meetbearcss from '../stylecomponents/meetbear.css';
class MeetBear extends Component {
    constructor(){
    super();
    this.state = {
      

    };
}

componentDidMount(){




$("#meetbearpause").click(function(){
    $("#meetbear_video_pause").get(0).pause();
    $("#meetbearpause").hide();
    $("#meetbearplay").show();
});

$("#meetbearplay").click(function(){
    $("#meetbear_video_pause").get(0).play();
    $("#meetbearplay").hide();
    $("#meetbearpause").show();
});


$("#meetbearpause2").click(function(){
    $("#meetbear_video_pause").get(0).pause();
    $("#meetbearpause2").hide();
    $("#meetbearplay2").show();
});

$("#meetbearplay2").click(function(){
    $("#meetbear_video_pause").get(0).play();
    $("#meetbearplay2").hide();
    $("#meetbearpause2").show();
});






}

render(){
const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>



<section className="swarovski_bear" id="meet_bers_section">
    <img className="hide_img" src={meetbear} alt="" />
    <img className="show_img" src={mobiletitle} alt="" />
</section>
<section className="meet_bearwrap" >
    <div className="video_box">
        <video id="meetbear_video_pause"  src={meetbearvideo} autoPlay loop muted />
        <div className="video_info">
            <div className="flex_row">
                <div className="limited_box">
                    <h3>Limited Edition Build-A-Bear 25th Celebration NFT Featuring Swarovski® crystals  </h3>
                    <p>(includes the physical bear)</p>
                </div>
                <div className="bid_now">
                    <a href="#">Bid Now</a>
                </div>
                <div className="col-sm-2 playbtn">
                    <img id="meetbearpause" src={playbutton} alt="" />
                    <img id="meetbearplay" src={meetbearplay} alt="" />
                    <div className="playbtn meet_bplay">
                        <img id="meetbearpause2" src={playbtn} alt="" />
                        <img id="meetbearplay2" src={mobileplaybtn1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



</React.Fragment>
    )
}
}
export default MeetBear;
