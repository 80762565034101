import React, {Component} from 'react';
import $ from 'jquery';
import staytouch from '../assets/images/staytouch.png';
import Staytouchcss from '../stylecomponents/staytouch.css';
class Staytouch extends Component {
    constructor(){
    super();
    this.state = {
      

    };
}

render(){
const lang =JSON.parse(localStorage.getItem("lang"));
return(
<React.Fragment>


<section className="stay_touch" id="staytouch_wrap">
    <div className="container">
        <div className="stay_intouch">
            <img src={staytouch} alt="" />
            <p>Join our mailing list to stay in the loop with our continuing roadmap, PAWsome upcoming releases and more!</p>
        </div>
        <div className="formbox">
            <form action="" method="">
                <div className="input_box_stay">
                    <input type="text" placeholder="Email" name="" />
                    <button type="button">Subscribe</button>
                </div>
            </form>
        </div>
    </div>
</section>



</React.Fragment>
    )
}
}
export default Staytouch;
